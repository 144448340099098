import React, { FC } from 'react';
import { DangerouslySetInnerHtml } from 'layout';
import { ITextBlockProps } from './model';

const TextBlock: FC<ITextBlockProps> = ({ html, className, element = 'div' }) => {
  return (
    <DangerouslySetInnerHtml className={`text-block ${className}`} element={element} html={html} />
  );
};

export default TextBlock;
