import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Layout, Col } from 'layout';
import HeroBanner from 'components/ArticleListingHeroBanner';
import ArticleBody from 'components/ArticleBody';
import ArticleSubMenu from 'components/ArticleSubMenu';
import RelatedArticles from 'components/RelatedArticles';
import { breakpointsArticles } from 'components/RelatedArticles/constants';
import useScreenRecognition from 'hooks/useScreenRecognition';
import RelatedProductArticle from 'components/relatedProductArticle/relatedProductArticle';
import { TArticlePageComponentProps } from './models';
import './ArticlePageMain.scss';
import './ArticlePageRTL.scss';

const ArticlePage: FC<TArticlePageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    page: {
      url,
      defaultCompositions,
      properties: {
        seoMetaTitle,
        seoCanonicalUrl,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        title,
        articleBodyImageAltText,
        articleBody,
        bodySubnavigation,
        tags,
        topicsTitle,
        relatedArticlesTitle,
        relatedArticlesCTAButtonText,
        relatedArticlesCTAButtonAriaLabel,
        relatedProducts,
        relatedProductsLabel,
      },
      image,
      imageSm,
      imageMd,
    },
    relatedArticles,
  },
}) => {
  const { isMobile, isTablet } = useScreenRecognition();

  const [relatedProductsCountry, setRelatedProductsCountry] = useState<any[]>();

  const openGraphImage = image?.childImageSharp?.fluid.src;
  const isBrowser = typeof window !== 'undefined';
  let country: string | null = '';

  if (isBrowser) {
    country = localStorage.getItem('codeCountry');
  }

  useEffect(() => {
    setRelatedProductsCountry(
      relatedProducts?.filter(({ properties: product }) => product.cta[0].url.includes(country))
    );
  }, [relatedProducts]);

  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoCanonicalUrl,
        seoExternalHreflangs,
        openGraphImage,
      }}
      className="blog-article-page"
    >
      <HeroBanner
        inFirstViewport
        url={url}
        title={title}
        image={isMobile ? imageSm : isTablet ? imageMd : image}
        hasVerticalPadding
        altImageText={articleBodyImageAltText}
        className="article-listing-hero-banner"
      />
      <Container className="blog-article-page__content">
        <Row>
          <Col lg="2" xl="2">
            <ArticleSubMenu
              content={articleBody}
              bodySubNavigation={bodySubnavigation}
              tags={tags}
              articleSubMenuTopics={topicsTitle}
            />
          </Col>
          <Col sm="12" md="12" lg="9" xl="9">
            <ArticleBody content={articleBody} />
            <RelatedArticles
              breakpoints={breakpointsArticles}
              data={relatedArticles.nodes}
              title={relatedArticlesTitle}
              readMoreText={relatedArticlesCTAButtonText}
              ariaLabel={relatedArticlesCTAButtonAriaLabel || relatedArticlesCTAButtonText}
            />

            <div className="products__container">
              {relatedProductsLabel && relatedProducts && relatedProducts.length ? (
                <h3 className="title-container">{relatedProductsLabel}</h3>
              ) : null}
              <div className="article__container">
                {relatedProductsCountry && relatedProductsCountry.length > 0
                  ? relatedProductsCountry.map(({ properties: article }) => (
                      <RelatedProductArticle
                        {...article}
                        textButton={relatedArticlesCTAButtonText}
                      />
                    ))
                  : null}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($url: String = "", $links: [String] = "", $lang: String) {
    page: umbracoArticles(url: { eq: $url }, lang: { eq: $lang }) {
      url
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      image: localImage {
        childImageSharp {
          fluid(maxWidth: 3400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSm: localImage {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageMd: localImage {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      properties {
        ...FragmentArticlePageProperties
        seoMetaDescription
        seoMetaTitle
        seoCanonicalUrl
        seoNoIndex
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        relatedArticlesTitle
        tags {
          name
          url {
            url
            name
          }
        }
      }
    }
    relatedArticles: allUmbracoArticles(filter: { url: { in: $links } }) {
      nodes {
        id
        ...FragmentRelatedArticle
        url
        localImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;
export default ArticlePage;
