import React from 'react';
import ArticleSubMenuItem from 'gatsby-theme-husky/src/components/ArticleSubMenuItem';
import ARTICLES from 'gatsby-theme-husky/src/shared/static/content';

const ArticleSubMenu = ({ content }) => {
  return (
    <ul className="article-submenu__list">
      {content
        .filter(({ structure }) => structure === ARTICLES.SECTION)
        .map(({ properties: { articleSectionTitle } }) =>
          articleSectionTitle ? (
            <ArticleSubMenuItem
              key={articleSectionTitle}
              name={articleSectionTitle}
              linkToSection={articleSectionTitle}
            />
          ) : null
        )}
    </ul>
  );
};

export default ArticleSubMenu;
